import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Stack,
  IconButton,
  Box,
} from '@mui/material';
import { Close, Navigation } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../locales/translations';
import GoogleIcon from '../_include/icons/GoogleIcon';
import WazeAppIcon from '../_include/icons/WazeAppIcon';
import { AddressWithErrors } from '../../models/Missions';
import { formatAddress } from '../../helpers/geo';

interface BaseNavigationDialogProps {
  open: boolean;
  onClose: () => void;
}

// Variant for a single coordinate (location-based navigation)
// Renders buttons for Google Maps and Waze.
interface LocationNavigationDialogProps extends BaseNavigationDialogProps {
  variant: 'location';
  coordinates: {
    lat: number;
    lng: number;
  };
}

// Variant for a multi-stop route (route-based navigation)
// Renders a single button for Google Maps that supports waypoints.
interface RouteNavigationDialogProps extends BaseNavigationDialogProps {
  variant: 'route';
  addresses: AddressWithErrors[];
};


type NavigationDialogProps = LocationNavigationDialogProps | RouteNavigationDialogProps;

const NavigationDialog: React.FC<NavigationDialogProps> = (props) => {
  const { open, onClose } = props;
  const { t } = useTranslation([Namespace.TITLES]);

  // Shared button styles
  const buttonStyles = {
    fontSize: '16px',
    fontWeight: '600',
    padding: '10px 16px',
    backgroundColor: '#FFFFFF',
    color: '#414651',
    border: '1px solid var(--Gray-300, #D5D7DA)',
    boxShadow: '0px 1px 2px 0px rgba(10, 13, 18, 0.05)',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
    '&:active': {
      backgroundColor: '#FFFFFF',
    },
  };

  // Render content based on the variant
  const renderContent = () => {
    if (props.variant === 'location') {
      const { coordinates } = props;

      const openGoogleMaps = () => {
        const url = `https://www.google.com/maps/dir/?api=1&destination=${coordinates.lat},${coordinates.lng}`;
        window.open(url, '_blank');
        onClose();
      };

      const openWaze = () => {
        const url = `https://www.waze.com/ul?ll=${coordinates.lat},${coordinates.lng}&navigate=yes`;
        window.open(url, '_blank');
        onClose();
      };

      return (
        <Stack spacing={2}>
          <Button
            variant="contained"
            disableElevation
            onClick={openGoogleMaps}
            fullWidth
            sx={buttonStyles}
            startIcon={<GoogleIcon />}
          >
            Google Maps
          </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={openWaze}
            fullWidth
            sx={buttonStyles}
            startIcon={<WazeAppIcon />}
          >
            Waze
          </Button>
        </Stack>
      );
    } else if (props.variant === 'route') {
      const { addresses } = props;
      // Filter out only non-visited addresses and select the top 7 due to the max no of waypoints you can pass to google maps url includeing origin and destination.
      const nonVisitedAddresses = addresses.filter((addr) => !addr.visited);
      const top7 = nonVisitedAddresses.slice(0, 7);

      const openGoogleMaps = () => {
        if (top7.length === 0) return;

        // Use the last address as destination.
        const destinationAddress = top7[top7.length - 1];
        const destinationStr =
          formatAddress(destinationAddress.address) ||
          `${destinationAddress.lat},${destinationAddress.lng}`;

        // Use the first 6 addresses (if any) as waypoints.
        const waypointAddresses = top7.slice(0, top7.length - 1);
        const waypointsStr = waypointAddresses
          .map((addr) => {
            const formatted = formatAddress(addr.address);
            return formatted || `${addr.lat},${addr.lng}`;
          })
          .join('|');

        // Build the Google Maps URL with origin, destination and optional waypoints.
        let url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
          destinationStr
        )}`;
        if (waypointsStr) {
          url += `&waypoints=${encodeURIComponent(waypointsStr)}`;
        }

        window.open(url, '_blank');
        onClose();
      };


      return (
        <Button
          variant="contained"
          disableElevation
          onClick={openGoogleMaps}
          fullWidth
          sx={buttonStyles}
          startIcon={<GoogleIcon />}
        >
          Google Maps
        </Button>
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      sx={{
        borderRadius: '12px',
        overflow: 'hidden',
      }}
    >
      {/* Header Section */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ position: 'relative', py: 2 }}
      >
        {/* Centered Header Icon */}
        <Navigation />

        {/* Close Button */}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '8px',
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <Close />
        </IconButton>
      </Box>

      {/* Title */}
      <DialogTitle align="center" sx={{ fontWeight: '500' }}>
        {t('open_navigation', { ns: Namespace.TITLES })}
      </DialogTitle>

      {/* Content Section */}
      <DialogContent sx={{ px: 3, py: 2 }}>{renderContent()}</DialogContent>
    </Dialog>
  );
};

export default NavigationDialog;
