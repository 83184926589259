export enum CollectionType {
    /** "Grey" waste, common waste from a household in garbage bags. */
    HOUSEHOLD_WASTE = "household_waste",

    /** Sortable waste that can mostly be recycled. Usually put in yellow-lid bins or in yellow garbage bags. */
    SORTABLE_WASTE = "sortable_waste",

    /** Household waste after sorting collection. */
    RESIDUAL_WASTE = "residual_waste",

    /** Organic and green waste collection. */
    BIOWASTE = "biowaste",

    /** Glass objects collection (bottles, jars...). */
    GLASS_WASTE = "glass_waste",

    /** Hidden collection not to be displayed to client. */
    HIDDEN = "hidden",
}