import { DocumentData, DocumentSnapshot, QueryDocumentSnapshot, collection, doc, getDocs, getFirestore, setDoc } from "firebase/firestore";
import { DbCollection } from "../constants/db";
import { AddressWithErrors } from "../models/Missions";
import { MissionsActions } from "../store/reducers/missions/list";
import { AppDispatch } from "../store/store";
import { fetchAPI, handleAPIError } from "./actions";
import { getStorageURL, StorageType, uploadFile } from "../helpers/storage";
import { ReportFormData } from "../models/Forum";
import { showError, showSuccess } from "../store/reducers/snacks";
import { MissionAddressesActions } from "../store/reducers/missions/addresses";
import i18next, { Namespace } from "../locales/translations";
import { Coordinates } from "../helpers/geo";
import urls from "../constants/urls";

/**
 * Serialize a Mission's Address data from their database document
 */
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData>): AddressWithErrors;
function fromDbDoc(dbDoc: DocumentSnapshot<DocumentData>): AddressWithErrors;
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>) {
    const data = dbDoc.data() as AddressWithErrors;
    const missionAddressData: AddressWithErrors = {
        ...data,
        ID: dbDoc.id,
    };

    return missionAddressData;
}

/**
 * Retrieves all addresses for a specific mission.
 * @param ambassadorEmail - The email of the current ambassador user.
 * @param partnerID - The ID of the partner.
 * @param missionID - The ID of the mission.
 * @returns The list of address documents.
 */
const getAddresses = (ambassadorEmail: string, partnerID: string, missionID: string) => async (dispatch: AppDispatch) => {
    dispatch(MissionAddressesActions.startLoading());

    try {
        const db = getFirestore();
        const addressesRef = collection(db, DbCollection.PARTNERS, partnerID, DbCollection.MISSIONS, missionID, DbCollection.SENSITIZATION_ADDRESSES);
        const addressesSnapshot = await getDocs(addressesRef);

        const missionAddresses: AddressWithErrors[] = addressesSnapshot.docs
            .map(fromDbDoc)
            .filter(address => address.assigned?.email === ambassadorEmail);

        dispatch(MissionAddressesActions.setAddresses(missionAddresses));
        return missionAddresses;
    } catch (e) {
        dispatch(handleAPIError(e, "fetching mission addresses", MissionsActions.setError));
        return null;
    }
};

export const optimizeAddressRoute = (
    partnerID: string,
    startingPoint: Coordinates | null,
    addresses: AddressWithErrors[]
) => async (dispatch: AppDispatch) => {
    dispatch(MissionAddressesActions.startLoading());
    try {

        const nonVisitedAddresses = addresses.filter(address => !address.visited);
        const visitedAddresses = addresses.filter(address => address.visited);

        if (nonVisitedAddresses.length === 0) {
            throw new Error('No valid addresses available for route optimization');
        }

        let firstPoint;

        // Determine starting point
        let effectiveStartingPoint: Coordinates;
        if (startingPoint && startingPoint.latitude !== 0 && startingPoint.longitude !== 0) {
            // Use provided starting point if valid
            effectiveStartingPoint = startingPoint;
        } else {
            // Use first valid point as starting point
            firstPoint = nonVisitedAddresses[0];
            effectiveStartingPoint = {
                latitude: firstPoint.lat,
                longitude: firstPoint.lng
            };

            // Remove the first point from the list of points to optimize
            nonVisitedAddresses.shift();
        }

        // Prepare request body with strict type checking
        const points = nonVisitedAddresses.map((address) => ({
            id: address.ID,
            coordinates: {
                latitude: parseFloat(address.lat.toFixed(6)),
                longitude: parseFloat(address.lng.toFixed(6))
            },
        }));

        const response = await fetchAPI(
            `${urls.API}/partner/${partnerID}/addresses/sort-itinerary`,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    startPoint: {
                        latitude: parseFloat(effectiveStartingPoint.latitude.toFixed(6)),
                        longitude: parseFloat(effectiveStartingPoint.longitude.toFixed(6))
                    },
                    points
                }),
            }
        );

        // Reorder addresses based on optimized route
        const addressMap = new Map(nonVisitedAddresses.map((addr) => [addr.ID, addr]));
        const sortedAddresses = (
            (response as Array<{ id: string }>)
                .map(({ id }) => {
                    const address = addressMap.get(id);
                    if (!address) {
                        throw new Error(`Address ${id} not found in original list`);
                    }
                    return address;
                })
        );

        // If firstPoint exists, add it to the beginning of the sorted addresses
        if (firstPoint) {
            sortedAddresses.unshift(firstPoint);
        }

        // Combine sorted non-visited addresses with visited addresses at the end
        const finalSortedAddresses = [...sortedAddresses, ...visitedAddresses];

        // Update Redux state
        dispatch(MissionAddressesActions.setSortedAddresses(finalSortedAddresses));

        // Show success message
        dispatch(showSuccess(i18next.t('address_sorted_successfully', { ns: Namespace.SNACKS })))

        return sortedAddresses;
    } catch (e) {
        const error = e as Error;
        console.error("Route Optimization Error:", error.message);
        dispatch(showError(error.message));
        return null;
    }
};

/**
 * Action to update mission addresses in Firestore individually, including image uploads.
 * 
 * @param partnerID - The ID of the partner.
 * @param missionID - The ID of the mission.
 * @param updatedMissionAddress - A partial object of AddressWithErrors to update.
 * @param images - An array of files representing the images to upload.
 */
const updateMissionAddress = (
    partnerID: string,
    missionID: string,
    addressID: string,
    updatedMissionAddress: ReportFormData,
    images: File[]
) => async (dispatch: AppDispatch) => {

    try {
        dispatch(MissionAddressesActions.startLoading());
        const db = getFirestore();

        // Reference to the 'sensitization_addresses' subcollection
        const addressesCollectionRef = collection(
            db,
            DbCollection.PARTNERS,
            partnerID,
            DbCollection.MISSIONS,
            missionID,
            DbCollection.SENSITIZATION_ADDRESSES
        );

        // Reference to the specific address document
        const addressDocRef = doc(addressesCollectionRef, addressID);

        const addressImagesUrl = getStorageURL(StorageType.MISSION_ADDRESSES);

        // Handle image uploads
        const uploadedImageURLs = await Promise.all(
            images.map(async (image) => {
                const filepath = `${missionID}/${addressID}/${Date.now()}_${image.name}`;
                const storagePath = `${addressImagesUrl}/${filepath}`;
                await uploadFile(image, storagePath);
                return filepath;
            })
        );

        // Update the report with uploaded image URLs directly inside the FormData
        const updatedReport: ReportFormData = {
            ...updatedMissionAddress, // All fields from FormData
            imageURLs: uploadedImageURLs, // Attach the uploaded image URLs here
        };

        // Update the address document with merged data
        await setDoc(addressDocRef, { visited: true, report: updatedReport }, { merge: true });

        dispatch(MissionAddressesActions.updateAddressVisitedStatus({ addressID, changes: { visited: true }, }));
        dispatch(showSuccess(i18next.t('address_report_updated', { ns: Namespace.SNACKS })));

    } catch (error) {
        let errorMessage = i18next.t('unknown_error', { ns: Namespace.SNACKS });

        dispatch(handleAPIError(error, errorMessage, MissionsActions.setError));
    } finally {
        dispatch(MissionAddressesActions.stopLoading());
    }
};


const MissionAddressesMethods = {
    getAddresses,
    updateMissionAddress,
    optimizeAddressRoute
};

export default MissionAddressesMethods;
