import { AdvancedMarker } from '@vis.gl/react-google-maps';
import { useEffect, useState } from "react";
import { getCurrentUserLocation } from '../../helpers/geo';
import { Coords } from '../../store/reducers/batches/map';
import { Box, keyframes } from "@mui/material";
import { useAppDispatch } from '../../store/hooks';
import { UserActions } from '../../store/reducers/users';

const ripple = keyframes`
    0% {
        transform: scale(1.3);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1.3);
    }
`;

const UserLocationMarker: React.FC = () => {
    const [userLocation, setUserLocation] = useState<Coords | null>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const updateUserLocation = async () => {
            const location = await getCurrentUserLocation();
            if (location) {
                dispatch(UserActions.setUserLocation(location));
                setUserLocation(location);
            }
        };

        updateUserLocation();
        const intervalId = setInterval(updateUserLocation, 10000);
        return () => clearInterval(intervalId);
    }, [dispatch]);

    return userLocation ? (
        <AdvancedMarker
            position={userLocation}
        >
            <Box
                id="test-pos"
                component="span"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                width={theme => theme.spacing(3)}
                height={theme => theme.spacing(3)}
                ml={2}
                bgcolor={theme => theme.palette.info.main}
                position="relative"
                boxShadow={4}
                sx={{
                    overflow: "hidden",
                    "&::before": {
                        content: '""',
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        border: "5px #fff solid",
                        borderRadius: "50%",
                        animation: `${ripple} 3s ease-in-out infinite`,
                    }
                }}
            />
        </AdvancedMarker>
    ) : null;
};

export default UserLocationMarker;