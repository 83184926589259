import { createSlice, createEntityAdapter, PayloadAction } from "@reduxjs/toolkit";
import { AddressWithErrors } from "../../../models/Missions";
import { LoadableContext, RootState } from "../../store";

const addressesAdapter = createEntityAdapter<AddressWithErrors>({
    selectId: (address) => address.ID,
});

type AddressesState = ReturnType<typeof addressesAdapter.getInitialState> & LoadableContext & {
    missionID: string | null;
    sorted: boolean;
};

const initialState: AddressesState = addressesAdapter.getInitialState({
    loading: false,
    error: null,
    missionID: null,
    sorted: false,
});

const addressesSlice = createSlice({
    name: 'mission_addresses',
    initialState,
    reducers: {
        setMissionID: (state, action: PayloadAction<string>) => {
            state.missionID = action.payload;
            addressesAdapter.removeAll(state);
        },
        setAddresses: (state, action: PayloadAction<AddressWithErrors[]>) => {
            const visitedAddressesOnTop = [
                ...action.payload.filter(address => !address.visited),
                ...action.payload.filter(address => address.visited)
            ];
            addressesAdapter.setAll(state, visitedAddressesOnTop);
            state.sorted = false;
            state.loading = false;
            state.error = null;
        },
        addAddresses: (state, action: PayloadAction<AddressWithErrors[]>) => {
            addressesAdapter.addMany(state, action.payload);
            state.loading = false;
            state.error = null;
        },
        updateAddressVisitedStatus: (state, action: PayloadAction<{ addressID: string; changes: Partial<AddressWithErrors> }>) => {
            addressesAdapter.updateOne(state, {
                id: action.payload.addressID,
                changes: action.payload.changes,
            });
            state.loading = false;
        },
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setSortedAddresses: (state, action: PayloadAction<AddressWithErrors[]>) => {
            addressesAdapter.setAll(state, action.payload);
            state.sorted = true;
            state.loading = false;
            state.error = null;
        },
        stopLoading: (state) => {
            state.loading = false;
            state.error = null;
        },
        setError: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        },
        clearAddresses: (state) => {
            addressesAdapter.removeAll(state);
            state.missionID = null;
            state.loading = false;
            state.error = null;
        },
    },
});

export const MissionAddressesActions = addressesSlice.actions;

export const {
    selectAll: selectAllMissionAddresses,
    selectById: selectMissionAddressById,
    selectIds: selectMissionAddressesIds
} = addressesAdapter.getSelectors((state: RootState) => state.mission.addresses);


const MissionAddressesReducer = addressesSlice.reducer

export default MissionAddressesReducer;
