import React, { useCallback, useState } from 'react';
import { Box, CircularProgress, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../../locales/translations';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import MissionAddressesMethods from '../../../actions/missionAddresses';
import ActionButton from '../../_include/Buttons/ActionButton';
import { showError } from '../../../store/reducers/snacks';
import { selectAllMissionAddresses } from '../../../store/reducers/missions/addresses';
import { Refresh } from '@mui/icons-material';
import theme from '../../../constants/theme';
import NavigationDialog from '../NavigationDialog';
import { MissionStatus } from '../../../models/Missions';

const MissionAddressListHeader: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation([Namespace.ADDRESSES, Namespace.SNACKS, Namespace.ACTIONS]);
    const userLocation = useAppSelector(state => state.user.location);
    const partnerID = useAppSelector((state) => state.user.partnerID);
    const loading = useAppSelector((state) => state.mission.addresses.loading);
    const addresses = useAppSelector(state => selectAllMissionAddresses(state));
    const error = useAppSelector((state) => state.mission.selected.error);
    const missionStatus = useAppSelector((state) => state.mission.selected.selectedMission?.status);
    const isSorted = useAppSelector((state) => state.mission.addresses.sorted);
    const [isNavigationModalOpen, setIsNavigationModalOpen] = useState(false); // Track navigation dialog state
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    // Handle sorting addresses
    const handleSortAddresses = useCallback(async () => {
        const startingPoint = {
            latitude: userLocation?.lat || 0,
            longitude: userLocation?.lng || 0,
        };

        await dispatch(
            MissionAddressesMethods.optimizeAddressRoute(partnerID!, startingPoint, addresses)
        );
    }, [addresses, partnerID, dispatch, userLocation?.lat, userLocation?.lng]);

    // Handle start navigation button click
    const handleStartNavigation = useCallback(() => {
        if (addresses.length > 0) {
            setIsNavigationModalOpen(true); // Open the navigation dialog
        } else {
            dispatch(showError(t('no_addresses_to_navigate', { ns: Namespace.SNACKS })));
        }
    }, [addresses, dispatch, t]);


    if (error) {
        return (
            <Typography variant="body1" color="error">
                {t('error_loading_mission', { ns: Namespace.SNACKS })}
            </Typography>
        );
    }

    return (
        <Box px={2} pt={3}>
            <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
            >
                {/* Title Grid Item */}
                <Grid
                    item
                    xs={12}
                    sm={5}
                    display="flex"
                    flexDirection='column'
                    justifyContent={isSmallScreen ? 'center' : 'flex-start'}
                >
                    <Typography
                        variant="h4"
                        color="text.secondary"
                        margin={0}
                        align={isSmallScreen ? 'center' : 'left'}
                    >
                        {t('address_to_visit', { ns: Namespace.ADDRESSES })}
                    </Typography>
                    {isSorted &&
                        <Typography pt={1} variant="body2" color="grey" align={isSmallScreen ? 'center' : 'left'}>
                            {t('addresses_sorted_for_you', { ns: Namespace.ADDRESSES })}
                        </Typography>
                    }
                </Grid>
                {isSorted && (
                    <Grid
                        item
                        xs={12}
                        sm={2}
                        display="flex"
                        justifyContent={isSmallScreen ? 'center' : 'flex-end'}
                        alignItems="center"
                        gap={1}
                    >
                        <IconButton
                            aria-label="refresh"
                            size="medium"
                            color="inherit"
                            onClick={handleSortAddresses}
                            disabled={loading}
                        >
                            {loading ? (
                                <CircularProgress size={20} />
                            ) : (
                                <Refresh fontSize="inherit" />
                            )}
                        </IconButton>
                    </Grid>
                )}

                {/* Actions Grid Item */}
                {missionStatus !== MissionStatus.COMPLETED &&
                    <Grid
                        item
                        xs={12}
                        sm={5}
                        display="flex"
                        justifyContent={isSmallScreen ? 'center' : 'flex-end'}
                        alignItems="center"
                        gap={1}
                    >
                        <ActionButton
                            fullWidth={isSmallScreen}
                            size='normal'
                            loading={loading}
                            onClick={isSorted ? handleStartNavigation : handleSortAddresses}
                        >
                            {isSorted
                                ? t('start_navigation', { ns: Namespace.ACTIONS })
                                : t('generate_an_itinerary', { ns: Namespace.ACTIONS })}
                        </ActionButton>
                    </Grid>
                }
            </Grid>


            {/* Navigation Dialog */}
            {isSorted && addresses.length > 0 && (
                <NavigationDialog
                    variant="route"
                    open={isNavigationModalOpen}
                    onClose={() => setIsNavigationModalOpen(false)}
                    addresses={addresses}
                />
            )}
        </Box>
    );
};

export default MissionAddressListHeader;