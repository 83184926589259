import { Marker } from "@googlemaps/markerclusterer";
import { darken, Typography } from "@mui/material";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { useCallback, useEffect } from "react";
import { slugify } from "../../helpers/strings";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { MissionMapActions } from "../../store/reducers/missions/map_reducer";
import MarkerIcon from "../_include/icons/MarkerIcon";
import { useNavigate } from "react-router";
import { AppRoutes } from "../../constants/routes";
import { AddressWithErrors } from "../../models/Missions";
import { DANGER_COLORS } from "../../helpers/draw";
import { DangerLevel } from "../../helpers/geo";

type AddressMarkerProps = {
    /**
     * Sequential index displayed on the marker for the mission detail.
     */
    index?: number;
    point?: AddressWithErrors;
    position: google.maps.LatLngLiteral;
    addressKey: string;
    providerId?: string;
    missionID?: string; // only passed on the mission map to identify if the marker is from a mission map
    color: string;
    zIndex: number;
    /**
     * Click handler for the marker.
     * @param addressKey - The unique address key.
     * @param position - Optional marker position.
     * @param providerIDs - Optional list of provider IDs.
     */
    onClick?: (addressKey: string, position?: google.maps.LatLngLiteral, providerIDs?: string[],) => void;
    setMarkerRef?: (marker: Marker | null, key: string) => void;
}

const MARKER_SIZE = 35;
const SELECTED_MARKER_SIZE = 40;

export default function AddressMarker({ index, point, position, addressKey, providerId, missionID, color, zIndex, onClick, setMarkerRef }: AddressMarkerProps) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const selected = useAppSelector(state => state.batch.map.selectedAddressKey === addressKey);
    const markerSize = selected ? SELECTED_MARKER_SIZE : MARKER_SIZE;
    const missionSorted = useAppSelector((state) => state.mission.addresses.sorted);

    const addressClicked = useAppSelector(state => state.mission.map.selectedAddressKey === addressKey);
    useEffect(() => {
        if (selected) dispatch(MissionMapActions.selectAddressKey(null)); // remove selected place ID to only trigger once
        if (addressClicked && onClick) onClick(addressKey, position); // zoom on marker when address clicked in list
    }, [addressClicked, position, addressKey, dispatch, onClick, selected]);

    const slugedAddressKey = slugify(addressKey);

    /**
    * Handles the click event on the marker.
    * Toggles the tooltip visibility and triggers data fetch actions for search map.
    */
    const handleMarkerClick = () => {
        if (onClick) onClick(addressKey, position, providerId ? [providerId] : undefined);

        else if (missionID) {
            navigate(
                `/${AppRoutes.MISSIONS}/${missionID}/${AppRoutes.BATCH_DETAIL}/${addressKey}`
            );
        }
    };

    const ref = useCallback(
        (marker: google.maps.marker.AdvancedMarkerElement | null) => {
            if (setMarkerRef) {
                setMarkerRef(marker, addressKey);
            }
        },
        [setMarkerRef, addressKey]
    );

    // If selected, further boost the z-index.
    const markerZIndex = selected ? zIndex + 99 : zIndex;

    return (
        <AdvancedMarker
            position={position}
            clickable
            onClick={handleMarkerClick}
            ref={ref}
            zIndex={markerZIndex}
        >
            <div style={{ position: 'relative' }}>
                <svg width={0} height={0}>
                    <linearGradient id={`linearColors-${slugedAddressKey}`} x1={1} y1={0} x2={1} y2={1}>
                        {missionID ?      // add the marker color on mission map for to indentify visited or not                           
                            (
                                <>
                                    <stop offset={0} stopColor={point?.visited ? DANGER_COLORS[DangerLevel.NONE] : DANGER_COLORS[DangerLevel.VERY_HIGH]} />
                                    <stop offset={1} stopColor={darken(point?.visited ? DANGER_COLORS[DangerLevel.NONE] : DANGER_COLORS[DangerLevel.VERY_HIGH], .3)} />
                                </>
                            ) : (
                                <>
                                    <stop offset={0} stopColor={color} />
                                    <stop offset={1} stopColor={darken(color, .3)} />
                                </>
                            )
                        }
                    </linearGradient>
                </svg>
                <MarkerIcon
                    sx={{
                        mt: `${-markerSize}px`,
                        ml: `${-markerSize / 2}px`,
                        fill: `url(#linearColors-${slugedAddressKey})`,
                        cursor: "pointer",
                        fontSize: markerSize,
                        stroke: "none",
                        ...(selected && {
                            stroke: "#fff",
                            strokeWidth: 1,
                        }),
                    }}
                />

                {index !== undefined && missionSorted && !point?.visited && (
                    <Typography
                        sx={{
                            position: 'absolute',
                            top: '-90%',
                            left: '-20%',
                            transform: 'translate(-50%, -50%)',
                            color: '#ffff',
                            fontSize: `${markerSize * 0.4}px`,
                            pointerEvents: 'none',
                            zIndex: 10
                        }}
                    >
                        {index + 1}
                    </Typography>
                )}
            </div>

            {/* </Tooltip> */}
        </AdvancedMarker>
    )
}
